import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Input, Button } from '@chakra-ui/react';
import { ethers } from 'ethers';

const MainMint = ({ getSigner, xenmapsAddress, xenmapsABI, viewsAddress, viewsABI, x1FastNetParams }) => {
  const [mintAmount, setMintAmount] = useState('');
  const [xenmap, setXenmap] = useState('');
  const [message, setMessage] = useState('');
  const [blockNumber, setBlockNumber] = useState('');
  const [searched, setSearched] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [svgImage, setSvgImage] = useState('');
  const [ownerAddress, setOwnerAddress] = useState('');
  const [dateMinted, setDateMinted] = useState('');
  const [blockNumberMetadata, setBlockNumberMetadata] = useState('');
  const [inscription, setInscription] = useState('');
  const [searchError, setSearchError] = useState('');
  const [isConnected] = useState(true);
  const fetchAndDisplaySVG = async (tokenId, signer) => {
    if (!tokenId) return;
    
    const xenmapsContract = new ethers.Contract(xenmapsAddress, xenmapsABI, signer);
    
    try {
      const tokenUri = await xenmapsContract.tokenURI(tokenId);
    
      let tokenData;
      if (tokenUri.startsWith('data:application/json;base64,')) {
        const base64JSON = tokenUri.split(',')[1];
        const decodedJson = atob(base64JSON);
        tokenData = JSON.parse(decodedJson);
      } else {
        tokenData = JSON.parse(tokenUri);
      }
    
      if (tokenData.image && tokenData.image.startsWith('data:image/svg+xml;base64,')) {
        const base64Svg = tokenData.image.split(',')[1];
        const decodedSvg = atob(base64Svg);
        setSvgImage(decodedSvg);
      } else {
        console.error("Unexpected image format in token data");
      }
    
      // Extract and set metadata directly from tokenData
      const nameAttribute = tokenData.name || ""; // Extract the block number from "name"
      const attributes = tokenData.attributes || [];
      const dateMintedAttribute = attributes.find(attr => attr.trait_type === "Minted On Date");
      const dateMinted = dateMintedAttribute ? dateMintedAttribute.value : "";
      const inscriptionAttribute = attributes.find(attr => attr.trait_type === "Message");
      const inscription = inscriptionAttribute ? inscriptionAttribute.value : "";
    
      setOwnerAddress(await xenmapsContract.ownerOf(tokenId)); // Set owner address
      setBlockNumberMetadata(nameAttribute); // Set the block number from "name"
      setDateMinted(dateMinted);
      setInscription(inscription);
      
    } catch (err) {
      console.error("Error fetching SVG and related data: ", err);
    }
  };
  
  useEffect(() => {
    if (tokenId && getSigner) {
      const signer = getSigner();
      if (signer) {
        fetchAndDisplaySVG(tokenId, signer).catch(console.error);
      }
    }
  }, [tokenId, getSigner]);

  const handleMint = async () => {
    if (!isConnected) {
      console.log('Not connected');
      return;
    }

    const signer = getSigner();
    if (!signer) {
      setSearchError("Signer not available. Please connect to the wallet.");
      return;
    }

    const chainId = await signer.getChainId();
    const expectedChainId = parseInt(x1FastNetParams.chainId, 16);

    if (chainId !== expectedChainId) {
      console.error("Wrong chain. Please connect to the correct network.");
      setSearchError("Please switch to the X1 FastNet network.");
      return;
    }

    const contract = new ethers.Contract(xenmapsAddress, xenmapsABI, signer);

    try {
      const transaction = await contract.mint(mintAmount);
      const receipt = await transaction.wait();

      const mintEvent = receipt.events?.find(event => event.event === "Minted");
      const mintedTokenId = mintEvent?.args?.tokenId;

      if (mintedTokenId) {
        setTokenId(mintedTokenId);
        setSearched(true);
        fetchAndDisplaySVG(mintedTokenId, signer);
      } else {
        console.error("Token ID not found in the mint event.");
        setSearchError("Error: Token ID not found after minting.");
      }
    } catch (err) {
      console.error("Mint error: ", err);
      setSearchError("Error: Token Already Exists or Bad Input");
    }
  };

  const parseMetadata = (tokenMetadataUri) => {
    try {
      let metadata = {};
  
      if (tokenMetadataUri.startsWith('data:application/json;base64,')) {
        const base64JSON = tokenMetadataUri.split(',')[1];
        const decodedJson = atob(base64JSON);
        metadata = JSON.parse(decodedJson);
      } else {
        metadata = JSON.parse(tokenMetadataUri);
      }
  
      return {
        dateMinted: metadata.dateMinted || '',
        blockNumber: metadata.blockNumber || '',
        inscription: metadata.inscription || '',
      };
    } catch (error) {
      console.error('Error parsing metadata:', error);
      return {
        dateMinted: '',
        blockNumber: '',
        inscription: '',
      };
    }
  };
  

  const handleSaveMessage = async () => {
    if (!isConnected || message.length === 0 || xenmap.length === 0) {
      setSearchError("Connect to the correct network and check fields.");
      return;
    }

    const signer = getSigner();
    if (!signer) {
      setSearchError("Signer not available. Please connect to the wallet.");
      return;
    }

    const contract = new ethers.Contract(xenmapsAddress, xenmapsABI, signer);

    try {
      setSvgImage("");
      setSearchError("");

      const tokenId = await contract.getTokenIdByBlockNumber(xenmap);
      if (!tokenId) {
        console.error("Token ID not found for the given block number.");
        setSearchError("Error: Token ID not found for the given block number.");
        return;
      }

      const transaction = await contract.writeBlockData(xenmap, message);
      await transaction.wait();

      console.log('Write Block Data transaction mined.');

      setTokenId(tokenId);
      setInscription(message);
      setSearched(true);

      setTimeout(() => {
        fetchAndDisplaySVG(tokenId, signer);
      }, 500);
    } catch (err) {
      console.error("Write Block Data error: ", err);
      setSearchError("Error writing data to XMAP");
    }
  };

  const handleSearch = async () => {
    if (!isConnected || blockNumber === "") {
      setSearchError("Please connect and provide a block number.");
      return;
    }
    const signer = getSigner();
    if (!signer) {
      setSearchError("Signer not available. Please connect to the wallet.");
      return;
    }
    console.log(`Searching for block number: ${blockNumber}`);

    const viewsContract = new ethers.Contract(viewsAddress, viewsABI, signer);
    const xenmapsContract = new ethers.Contract(xenmapsAddress, xenmapsABI, signer);

    try {
      setSvgImage("");

      const fetchedTokenId = await viewsContract.checkTokenIdByBlockNumber(blockNumber);
      console.log(`Fetched Token ID for block number ${blockNumber}: ${fetchedTokenId}`);
      if (fetchedTokenId === 0) {
        throw new Error("Token ID not found for the given block number.");
      }

      setTokenId(fetchedTokenId);
      fetchAndDisplaySVG(fetchedTokenId, signer);

      const ownerAddress = await xenmapsContract.ownerOf(fetchedTokenId);
      const tokenUri = await xenmapsContract.tokenURI(fetchedTokenId);
      console.log("Token URI:", tokenUri);

      let tokenData;
      if (tokenUri.startsWith('data:application/json;base64,')) {
        const base64JSON = tokenUri.split(',')[1];
        const decodedJson = atob(base64JSON);
        tokenData = JSON.parse(decodedJson);
      } else {
        tokenData = JSON.parse(tokenUri);
      }

      const attributes = tokenData.attributes || [];
      const dateMintedAttribute = attributes.find(attr => attr.trait_type === "Minted On Date");
      const dateMinted = dateMintedAttribute ? dateMintedAttribute.value : "";
      const inscriptionAttribute = attributes.find(attr => attr.trait_type === "Message");
      const inscription = inscriptionAttribute ? inscriptionAttribute.value : "";
      const blockNumberMetadataAttribute = attributes.find(attr => attr.trait_type === "Block Number");
      const blockNumberMetadata = blockNumberMetadataAttribute ? blockNumberMetadataAttribute.value : "";

      setOwnerAddress(ownerAddress);
      setDateMinted(dateMinted);
      setBlockNumberMetadata(blockNumberMetadata);
      setInscription(inscription);

      setSearchError("");
      setSearched(true);
    } catch (err) {
      console.error("Search error: ", err);

      setOwnerAddress("");
      setDateMinted("");
      setBlockNumberMetadata("");
      setInscription("");
      setSearchError("Error: Token not found");
      setTokenId(null);
      setSearched(false);
    }
  };
  return (
    <Flex flexDirection="column" alignItems="center" minHeight="80vh" marginTop="75px" >
      <Text fontSize="44px" className="title" textShadow="2px 2px 4px #000000" style={{ marginBottom: '10px' }}>Welcome to XenMaps.io</Text>
      <Text fontSize="20px" className="subtitle" textShadow="2px 2px 4px #000000" style={{ color: 'white', marginBottom: '10px' }}>Testing Is Live On The X1 FastNet</Text>
      <a href="https://xenmatrix.xenmaps.io" target="_blank" style={{ fontSize: '20px', color: 'limegreen', textDecoration: 'none', textShadow: '2px 2px 4px #000000', marginBottom: '20px' }}>Click Here To Enter The XenMatrix</a>
  
    <Box
      backgroundColor="rgba(50, 50, 50, 0.8)"
      padding="25px"
      borderRadius="25px"
      textAlign="center"
      marginBottom="40px"
      width="100%"
      maxWidth="600px"
    >
      <Text fontSize="34px" className="title" textShadow="2px 2px 4px #000000" marginBottom="10px">Mint Your $XMAP</Text>
      <Flex flexDirection="column" alignItems="center">
        <Flex justifyContent="center" marginBottom="0px">
          <Input readOnly={!isConnected} fontFamily="inherit" width="140px" height="40px" textAlign="center" marginRight="10px" type="text" placeholder="0.XenMap" value={mintAmount} onChange={(e) => setMintAmount(e.target.value)} />
          <Button backgroundColor="#00FF00" borderRadius="5px" boxShadow="0px 2px 2px 1px #0F0F0F" cursor="pointer" fontFamily="inherit" padding="10px 15px" fontSize="16px" onClick={handleMint}>Mint Now!</Button>
        </Flex>
        <Text fontSize="14px" color="white" marginBottom="5px" textAlign="center">Max 16 Characters Including .xenmap</Text>
      </Flex>

      <Text fontSize="34px" className="title" textShadow="2px 2px 4px #000000" marginBottom="10px">Write on Your $XMAP</Text>
      <Flex flexDirection="column" alignItems="center">
        <Flex justifyContent="center" marginBottom="0px">
          <Input readOnly={!isConnected} fontFamily="inherit" width="140px" height="40px" textAlign="center" marginRight="10px" type="text" placeholder="0.XenMap" value={xenmap} onChange={(e) => setXenmap(e.target.value)} />
          <Input readOnly={!isConnected} fontFamily="inherit" width="140px" height="40px" textAlign="center" marginRight="10px" type="text" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
          <Button backgroundColor="#00FF00" borderRadius="5px" boxShadow="0px 2px 2px 1px #0F0F0F" cursor="pointer" fontFamily="inherit" padding="10px 15px" fontSize="16px" onClick={handleSaveMessage}>Write on XenMap!</Button>
        </Flex>
        <Text fontSize="14px" color="white" marginBottom="5px" textAlign="center">Enter .xenmap ID and Message - 140 Characters Max</Text>
      </Flex>
      <Text fontSize="34px" className="title" textShadow="2px 2px 4px #000000" marginBottom="10px">Search by $XMAP</Text>
      <Flex flexDirection="column" alignItems="center">
        <Flex justifyContent="center" marginBottom="0px">
          <Input readOnly={!isConnected} fontFamily="inherit" width="140px" height="40px" textAlign="center" marginRight="10px" type="text" placeholder="0.XenMap" value={blockNumber} onChange={(e) => setBlockNumber(e.target.value)} />
          <Button backgroundColor="#00FF00" borderRadius="5px" boxShadow="0px 2px 2px 1px #0F0F0F" cursor="pointer" fontFamily="inherit" padding="10px 15px" fontSize="16px" onClick={handleSearch}>Search By $XMAP</Button>
        </Flex>
        <Text fontSize="14px" color="white" marginBottom="5px" textAlign="center">Search For Any .xenmap ID</Text>
      </Flex>
      <div style={{ marginBottom: '65px' }}></div>



        {searched && tokenId !== null && (
          <Box marginTop="60px" display="flex" flexDirection="column" alignItems="center">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ width: '400px', height: '700px', overflow: 'auto' }}>
                {svgImage ? (
                  <img src={`data:image/svg+xml,${encodeURIComponent(svgImage)}`} alt="Xenmap" />
                ) : (
                  <Text style={{ color: 'red' }}>{searchError || "Loading SVG..."}</Text>
                )}
              </div>
            </div>
          </Box>
        )}
        {searchError && (
          <Text color="red" marginTop="10px">{searchError}</Text>
        )}
        {searched && (
          <Box backgroundColor="black" marginTop="20px" maxWidth="800px">
            {/* Updated Block Number */}
            <Box border="1px solid white" padding="10px" marginBottom="10px">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="20px" color="white" textAlign="left">Block Number:</Text>
                {/* Updated to display blockNumberMetadata instead of blockNumber */}
                <Text fontSize="20px" color="limegreen" textAlign="right">{blockNumberMetadata}</Text>
              </Flex>
            </Box>
            {/* Updated Owner Address */}
            <Box border="1px solid white" padding="10px" marginBottom="10px">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="20px" color="white" textAlign="left">Owner Address:</Text>
                {/* Updated to display ownerAddress instead of blockNumber */}
                <Text fontSize="15px" color="limegreen" textAlign="right">{ownerAddress}</Text>
              </Flex>
            </Box>
            {/* Updated Date Minted */}
            <Box border="1px solid white" padding="10px" marginBottom="10px">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="20px" color="white" textAlign="left">Date Minted:</Text>
                {/* Updated to display dateMinted instead of blockNumber */}
                <Text fontSize="20px" color="limegreen" textAlign="right">{dateMinted}</Text>
              </Flex>
            </Box>
            {/* Updated Inscription */}
            <Box border="1px solid white" padding="10px" marginBottom="10px">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="20px" color="white" textAlign="left">Inscription:</Text>
                {/* Limit the inscription to 38 characters followed by an ellipsis */}
                <Text fontSize="20px" color="limegreen" textAlign="right">
                  {inscription.length > 38 ? `${inscription.slice(0, 30)}...` : inscription}
                </Text>
              </Flex>
            </Box>
          </Box>

        )}
      </Box>
      
        <Flex justifyContent="center">
            <span>
              <a href="https://explorer.x1-fastnet.infrafc.org/address/0x53BE48059b5999d032d0EFbeDe4dcDd637fC5Ec7" target="_blank" style={{ color: 'white' }}>XenMaps Contract</a>{' '}
              {'  -  '}
              <a href="https://explorer.x1-fastnet.infrafc.org/address/0x0D92e61250aB837411c285d12Cc8AfB82b44d2C8" target="_blank" style={{ color: 'white' }}>XenMaps Views Contract</a>
              {'  -  '}
              <a href="https://gitbook.xenmaps.io" target="_blank" style={{ color: 'white' }}>XenMaps Gitbook</a>
            </span>
          </Flex>
        <Text fontSize="12px" className="subtitle" textShadow="2px 2px 4px #000000" style={{ color: 'white', marginBottom: '0px' }}>
          Created by{' '}
          <a href="https://twitter.com/TreeCityWes" target="_blank" style={{ color: 'white' }}>@TreeCityWes</a>{' '}
          for the XEN Community and Ecosystem. Visit{' '}
          <a href="https://HashHead.io" target="_blank" style={{ color: 'white' }}>HashHead.io</a>,{' '}
          <a href="https://TreeCityTrading.us" target="_blank" style={{ color: 'white' }}>TreeCityTrading.us</a>
          <br/>

        </Text>
    </Flex>

    
  );
};

export default MainMint;
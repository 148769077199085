import React, { useState, useEffect, useRef } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { Flex, Image, Link, Text, Center, Button, Box } from '@chakra-ui/react';
import XenMapsLogo from './assets/social-media-icons/xenmaps-150x50.png';
import TwitterIcon from './assets/social-media-icons/twitter_32x32.png';
import GitHubIcon from './assets/social-media-icons/github_32x32.png';
import TelegramIcon from './assets/social-media-icons/telegram_32x32.png';
import './App.css';
import MainMint from './MainMint';
import xenmapsABI from './xenmapsABI.json';
import viewsABI from './viewsABI.json'
function App() {
  const [provider, setProvider] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [balance, setBalance] = useState('0');
  const [xmapBalance, setXMAPBalance] = useState('0');
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState('');
  const [isNetworkAdded, setIsNetworkAdded] = useState(false);
  const providerRef = useRef(null);

  const x1FastNetParams = {
    chainId: '0xFA3',
    rpcUrls: ['https://x1-fastnet.infrafc.org'],
    chainName: 'X1 FastNet',
    nativeCurrency: {
      name: 'XN',
      symbol: 'XN',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.x1-fastnet.infrafc.org'],
  };

  const getSigner = () => {
    if (!provider) {
      console.error('Ethereum provider is not available.');
      return null;
    }
    return provider.getSigner();
  };
  useEffect(() => {
    async function initialize() {
      const ethereumProvider = await detectEthereumProvider();
      if (ethereumProvider) {
        const ethersProvider = new ethers.providers.Web3Provider(ethereumProvider);
        setProvider(ethersProvider);
  
        ethereumProvider.on('chainChanged', handleChainChanged);
  
        try {
          const accounts = await ethersProvider.listAccounts();
          if (accounts.length > 0) {
            setAccounts(accounts);
            setIsConnected(true);
            await fetchBalances(accounts[0]);
          } else {
            setIsConnected(false);
            // Optionally, prompt the user to reconnect their wallet
          }
        } catch (error) {
          console.error('Error listing accounts:', error);
        }
      } else {
        setError('Please install MetaMask!');
      }
    }
  
    initialize();
  
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);
  
  useEffect(() => {
    const initializeBalances = async () => {
      if (provider && accounts.length > 0) {
        await fetchBalances(accounts[0]);
      }
    };
  
    initializeBalances();
  }, [provider, accounts]);
  
  const handleAccountsChanged = (newAccounts) => {
    if (newAccounts.length === 0) {
      setIsConnected(false);
      setBalance('0');
      setXMAPBalance('0');
      fetchBalances(accounts[0]);

    } else {
      setAccounts(newAccounts);
      setIsConnected(true);
      fetchBalances(newAccounts[0]);
    }
  };
  
  const handleChainChanged = async (chainId) => {
    if (parseInt(chainId, 16) !== parseInt(x1FastNetParams.chainId, 16)) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: x1FastNetParams.chainId }],
        });
        // Reinitialize the provider after successful network switch
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(ethersProvider);
  
        const newAccounts = await ethersProvider.listAccounts();
        setAccounts(newAccounts);
        fetchBalances(newAccounts[0]);
      } catch (switchError) {
        // Handle errors for switching network
      }
    } else {
      // Reinitialize the provider even if the network hasn't changed
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(ethersProvider);
  
      fetchBalances(accounts[0]);
    }
  };
  

  const addX1FastNetToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [x1FastNetParams],
        });
        setIsNetworkAdded(true);
        fetchBalances(accounts[0]);
      } else {
        setError('MetaMask not detected.');
      }
    } catch (error) {
      if (error.code === -32002) {
        setError('Network switch request is already pending. Please check MetaMask.');
        fetchBalances(accounts[0]);
      }
    }
  };

  const fetchBalances = async (account) => {
    if (!provider || !account) {
      setBalance('0');
      setXMAPBalance('0');
      return;
    }

    try {
      const nativeBalance = await provider.getBalance(account);
      setBalance(ethers.utils.formatEther(nativeBalance));

      const xenmapsAddress = "0x53BE48059b5999d032d0EFbeDe4dcDd637fC5Ec7"; // XenMaps address
      const xenMapContract = new ethers.Contract(xenmapsAddress, xenmapsABI, provider);
      const xmapBalanceValue = await xenMapContract.balanceOf(account);
      setXMAPBalance(xmapBalanceValue.toString());
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  const onConnect = async () => {
    if (!window.ethereum) {
      setError('Please install MetaMask!');
      return;
    }

    try {
      const newAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setAccounts(newAccounts);
      setIsConnected(true);
      fetchBalances(newAccounts[0]);
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
      setError('Error connecting to MetaMask.');
    }
  };

  const onDisconnect = () => {
    setAccounts([]);
    setIsConnected(false);
    setBalance('0');
    setXMAPBalance('0');
    setError('');
    fetchBalances(accounts[0]);
  };

  return (
    <div className="overlay">
      <div
        style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Flex
          className="nav-bar"
          justify="space-between"
          align="center"
          paddingY="5px"
          paddingX="40px"
          backgroundColor="rgba(0, 0, 0, 1)"
          width="100%"
          position="fixed"
          top="0"
        >
          <Flex align="center" width="50%">
            <Image src={XenMapsLogo} width="150px" height="50px" marginX="10px" />
            <Flex align="center">
              <Link href="https://twitter.com/xenmaps_io" isExternal>
                <Image src={TwitterIcon} boxSize="32px" marginX="10px" />
              </Link>
              <Link href="https://hashhead.io" isExternal>
                <Image src={TelegramIcon} boxSize="32px" marginX="10px" />
              </Link>
              <Link href="https://github.com/TreeCityWes" isExternal>
                <Image src={GitHubIcon} boxSize="32px" marginX="10px" />
              </Link>
            </Flex>
          </Flex>

          <Flex align="left" width="33%" justifyContent="center" alignItems="center">
            <Center>
              {!isConnected ? (
                <>
                  <Button
                    backgroundColor="#000000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0f0f0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    onClick={onConnect}
                  >
                    Connect
                  </Button>
                  <Button
                    marginLeft="10px"
                    backgroundColor="#000000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0f0f0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    onClick={addX1FastNetToMetaMask}
                  >
                    {isNetworkAdded ? 'Network Added' : 'Add X1 FastNet'}
                  </Button>
                </>
              ) : (
                <Flex align="center">
                  <Text color="white" marginRight="10px">
                    $XMAP: {xmapBalance}
                  </Text>
                  <Text color="white" marginRight="10px">
                    XN: {parseFloat(balance).toFixed(2)}
                  </Text>
                  <Box marginX="10px" opacity={isConnected ? 1 : 0.5} color="white">
                    {isConnected ? `Connected (${accounts[0]?.slice(0, 6)}...)` : 'Not Connected'}
                  </Box>
                  <Button
                    backgroundColor="#FF0000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0f0f0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    onClick={onDisconnect}
                  >
                    Disconnect
                  </Button>
                </Flex>
              )}
            </Center>
          </Flex>
        </Flex>
      </div>

      {error && (
        <Box color="red.500" textAlign="center" marginTop="4">
          {error}
        </Box>
      )}

      <MainMint
        provider={provider}
        accounts={accounts}
        getSigner={getSigner}
        x1FastNetParams={x1FastNetParams}
        xenmapsAddress="0x53BE48059b5999d032d0EFbeDe4dcDd637fC5Ec7"
        xenmapsABI={xenmapsABI}
        viewsAddress="0x0D92e61250aB837411c285d12Cc8AfB82b44d2C8"
        viewsABI={viewsABI}      />
    </div>
  );
}

export default App;
